import { request } from './axios'
import { front_fangke } from '@/utils/set.js'

export class landRelevant {
  /**
   * @description
   * @return {HttpResponse}
   */

  static async firstlogin(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umshare/firstlogin?address=${params.address}`,
      params,
      'post'
    )
  }

  static async getIsLoginApi() {
    return request(
      `${front_fangke}/api/unitxomarket/umshare/isLogin`,
      '',
      'post'
    )
  }

  static async getCurrencyApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umshare/currency?type=${params.type}`,
      params,
      'post'
    )
  }

  static async getMarketListApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/list`,
      params,
      'post'
    )
  }

  static async createCollectionApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umcollection/save`,
      params,
      'post'
    )
  }

  static async getCollectionApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umcollection/listByFour`,
      params,
      'post'
    )
  }

  static async getMarketSelectListApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/MarketByList`,
      params,
      'post'
    )
  }

  static async getSaveMarketApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/save`,
      params,
      'post'
    )
  }

  static async getCategoryApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umcollection/findUmCollectionByList`,
      params,
      'post'
    )
  }

  static async getMarketDetailsApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/look?id=${params.id}`,
      '',
      'post'
    )
  }

  static async getEventMarketDetailsApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/EventByLook?id=${params.id}`,
      '',
      'post'
    )
  }

  static async getCollectionDetailsApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umcollection/look?id=${params.id}`,
      '',
      'post'
    )
  }

  static async getEchartsApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarket/LineChartData?id=${params.id}&type=${params.type}`,
      '',
      'post'
    )
  }

  static async getDetailsMarketListApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarketcollection/UmMarketByCollectionId`,
      params,
      'post'
    )
  }

  static async getRelatedListApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/FindRecommend?id=${params.id}`,
      params,
      'post'
    )
  }

  static async getHolderListApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/ummarket/BuyMarketBYList?id=${params.id}`,
      params,
      'post'
    )
  }

  static async getProfileBalanceApi() {
    return request(
      `${front_fangke}/api/unitxomarket/umbalance/totalBalance`,
      '',
      'post'
    )
  }

  static async getProfileOrderApi(params) {
    return request(
      `${front_fangke}/api/unitxomarket/umorder/list`,
      params,
      'post'
    )
  }

  static async getProfileInviteApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/InviteList`,
      params,
      'post'
    )
  }

  static async getProfileCommissionApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/rebateDetails`,
      params,
      'post'
    )
  }

  static async getProfileWithDrawApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umbalance/Withdrawal`,
      params,
      'post'
    )
  }
  static async getCanWithDrawApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umbalance/TotalAmount`,
      params,
      'post'
    )
  }
  static async getWithDrawRecordApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umbalance/WithdrawalList`,
      params,
      'post'
    )
  }
  static async getInviteCodeApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/generateInvitCode?address=${params.address}`,
      '',
      'post'
    )
  }
  static async getUpdateInviteCodeApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/updateInvite`,
      params,
      'post'
    )
  }
  static async getLiquidityListApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarket/MarketsByOwn`,
      params,
      'post'
    )
  }
  static async getClaimWinningsApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umorder/claimWinnings?id=${params.id}`,
      '',
      'post'
    )
  }
  static async getSetRateApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/updateCommissionRate`,
      params,
      'post'
    )
  }
  static async getUserCodeApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/findUserCode`,
      params,
      'post'
    )
  }
  static async getSetUserApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/updateUserCodeByType`,
      params,
      'post'
    )
  }
  static async getResultListApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/list`,
      params,
      'post'
    )
  }
  static async getSetResultApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/setResult`,
      params,
      'post'
    )
  }
  static async getSetExamineApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/examineApprove`,
      params,
      'post'
    )
  }
  static async getSetExamineListApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/ExamineApproveList`,
      params,
      'post'
    )
  }
  static async getUpdateClaimLiquidityApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/updateIsClaimLiquidity?id=${params.id}`,
      '',
      'post'
    )
  }
  static async getCurrentIpApi() {
    http: return request(
      `${front_fangke}/api/unitxomarket/umshare/searcherIp`,
      '',
      'post'
    )
  }
  static async getCloseMarketsApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/closeMarket?id=${params.id}`,
      '',
      'post'
    )
  }
  static async getResetListApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarketevent/findUmMarketById?id=${params.id}`,
      '',
      'post'
    )
  }
  static async getHoldersListApi(params) {
    http: return request(
      `${front_fangke}/api/unitxomarket/ummarket/eventAnswerByCount?id=${params.id}`,
      '',
      'post'
    )
  }
  static async getSaveHashApi(params) {
    http: return request(
      `${front_fangke}/api/bargain/umfunctiontransactionhash/save`,
      params,
      'post'
    )
  }
}
