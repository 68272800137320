export const categoryData = [
  {
    value: 'Society',
    label: 'Society',
  },
  {
    value: 'Economy/Finance',
    label: 'Economy/Finance',
  },
  {
    value: 'Politics',
    label: 'Politics',
  },
  {
    value: 'Entertainment/Arts',
    label: 'Entertainment/Arts',
  },
  {
    value: 'Sports',
    label: 'Sports',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

export const modeSelect = [
  {
    value: '1',
    label: 'Directivity',
  },
  {
    value: '2',
    label: 'Single threshold',
  },
]
export const directionSelect = [
  {
    value: '1',
    label: 'UP',
  },
  {
    value: '2',
    label: 'DOWN',
  },
]
export const relationSelect = [
  {
    value: '1',
    label: 'Great than or equal to',
  },
  {
    value: '2',
    label: 'Less-than',
  },
  {
    value: '3',
    label: 'ELSE',
  },
]

export const bgColor = [
  {
    left: 'rgb(211, 84, 84)',
    right: 'rgb(200, 137, 110)',
  },
  {
    left: 'rgb(167, 137, 62)',
    right: 'rgb(233, 182, 88)',
  },
  {
    left: 'rgb(79, 90, 119)',
    right: 'rgb(85, 92, 111)',
  },
  {
    left: 'rgb(9, 134, 181)',
    right: 'rgb(7, 208, 124)',
  },
]
export const adminTabData = [
  {
    label: 'Audit',
    value: 1,
  },
  // {
  //   label: 'Unset',
  //   value: 0,
  // },
  {
    label: 'Passed',
    value: 2,
  },
  {
    label: 'Refused',
    value: 3,
  },
]
export const statusData = [
  {
    name: 'Unset',
    type: null,
    value: 0,
  },
  {
    name: 'Submitted',
    type: 'info',
    value: 1,
  },
  {
    name: 'Passed',
    type: 'success',
    value: 2,
  },
  {
    name: 'Refused',
    type: 'danger',
    value: 3,
  },
]
export const EngLetters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
export const chainData = [
  {
    chainId: '137',
    chainName: 'Polygon Mainnet',
  },
  {
    chainId: '80001',
    chainName: 'Polygon test',
  },
]
export const netData = [
  {
    chainName: 'Polygon',
    chainId: '0x89',
    ERC20TokenAdress: '0x244B742D2Bbd192f99560cBde01b2C955999c0A1',
    UnitxoMarketAddress: '0xa51EFA050405a20166A28B054942393aAfF615aD',
    rpcUrls: ['https://polygon-mainnet.infura.io'],
    interfacePrefix: '/polygon',
  },
  {
    chainName: 'Base',
    chainId: '0x2105',
    ERC20TokenAdress: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    UnitxoMarketAddress: '0xB14A59c97aca21919EC4e03E11c9b161E35B7Ae3',
    rpcUrls: ['https://mainnet.base.org'],
    blockExplorerUrls: ['https://base-sepolia.blockscout.com/'],
    interfacePrefix: '/base',
  },
]
