const routes = [
  {
    path: '/',
    name: 'root',
    meta: {
      title: 'root',
    },
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/welcome',
    meta: {
      title: 'admin',
    },
    component: () => import('@/views/admin/index.vue'),
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        meta: {
          title: 'welcome',
          requiresAuth: true,
        },
        component: () => import('@/views/admin/welcome.vue'),
      },
      {
        path: '/collection',
        name: 'collection',
        meta: {
          title: 'collection',
          requiresAuth: true,
        },
        component: () => import('@/views/createCollection.vue'),
      },
      {
        path: '/create',
        name: 'create',
        meta: {
          title: 'create',
          requiresAuth: true,
        },
        component: () => import('@/views/createMarket.vue'),
      },
      {
        path: '/createEvent',
        name: 'createEvent',
        meta: {
          title: 'createEvent',
          requiresAuth: true,
        },
        component: () => import('@/views/createEvent.vue'),
      },
      {
        path: '/setList',
        name: 'setList',
        meta: {
          title: 'setList',
          requiresAuth: true,
        },
        component: () => import('@/views/setResult.vue'),
      },
      {
        path: '/examine',
        name: 'examine',
        meta: {
          title: 'examine',
          requiresAuth: true,
        },
        component: () => import('@/views/examine.vue'),
      },
      {
        path: '/liquidity',
        name: 'liquidity',
        meta: {
          title: 'liquidity',
          requiresAuth: true,
        },
        component: () => import('@/views/liquidityList.vue'),
      },
      {
        path: '/users',
        name: 'users',
        meta: {
          title: 'users',
          requiresAuth: true,
        },
        component: () => import('@/views/users.vue'),
      },
    ],
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: 'search',
    },
    component: () => import('@/views/searchList.vue'),
  },
  {
    path: '/details',
    name: 'details',
    meta: {
      title: 'details',
    },
    component: () => import('@/views/details.vue'),
  },
  {
    path: '/eventDetails',
    name: 'eventDetails',
    meta: {
      title: 'eventDetails',
    },
    component: () => import('@/views/eventDetails.vue'),
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    meta: {
      title: 'portfolio',
      requiresAuth: true,
    },
    component: () => import('@/views/profile.vue'),
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    meta: {
      title: 'affiliate',
      requiresAuth: true,
    },
    component: () => import('@/views/affiliate.vue'),
  },
  // {
  //   path: '/portfolio',
  //   name: 'portfolio',
  //   meta: {
  //     title: 'portfolio',
  //     requiresAuth: true,
  //   },
  //   component: () => import('@/views/withdraw.vue'),
  // },
  {
    path: '/compilation',
    name: 'compilation',
    meta: {
      title: 'compilation',
    },
    component: () => import('@/views/compilationList.vue'),
  },
]

export default routes
