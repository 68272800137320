import axios from 'axios'
import { showMessage } from './status'
import { ElMessage } from 'element-plus'

axios.defaults.timeout = 180000
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

import { profix } from '@/utils/set.js'

//http request
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      jwttoken: localStorage.getItem('jwttoken'),
      userId: localStorage.getItem('accounts'),
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

//http response
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error
    if (response) {
      if (response.status == 401 || response.status == 403) {
        ElMessage({
          message: 'Login timeout, please login again!',
          type: 'warning',
        })
        localStorage.clear()
        setTimeout(() => {
          window.location.href = `/${profix}`
        }, 1000)
        return
      }
      // console.log('response.data.message：',response.data);
      if (response.data && response.data.message) {
        ElMessage({
          message: response.data.message,
          type: 'error',
        })
      } else {
        ElMessage({
          message: showMessage(response.status),
          type: 'error',
        })
      }
      return Promise.reject(response.data)
    } else {
      ElMessage({
        message: 'error! again',
        type: 'error',
      })
    }
  }
)

//  GET POST
export function request(url = '', params = {}, type = 'POST') {
  //url params type
  return new Promise((resolve, reject) => {
    let promise
    if (type.toUpperCase() === 'GET') {
      promise = axios({
        url,
        params,
      })
    } else if (type.toUpperCase() === 'POST') {
      promise = axios({
        method: 'POST',
        url,
        data: params,
      })
    }
    promise
      .then((res) => {
        if (res && res.data) {
          resolve(res.data)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
