import { defineStore } from 'pinia'

export const loginStore = defineStore('isLogin', {
  state: () => {
    return {
      isLogin: false,
      isAdministrator: false,
    }
  },
  actions: {
    updateLoginStatus(status) {
      this.isLogin = status
    },
    updateAdminStatus(status) {
      this.isAdministrator = status
    },
  },
  getters: {
    getLoginStatus: (state) => {
      return state.isLogin
    },
    getAdminStatus: (state) => {
      return state.isAdministrator
    },
  },
})
