import { netData } from './data'

const currentNet = localStorage.getItem('currentNet')
  ? [JSON.parse(localStorage.getItem('currentNet'))]
  : [netData[0]]
const protocol = window.location.protocol
console.log(currentNet, '--currentNet--')

export const netlist = currentNet
export const profix = ''
export const front_fangke = currentNet[0].interfacePrefix || '/polygon'
export const currentUrl = `${protocol}//${window.location.host}/${profix}`
export const chainId = currentNet.chainId

export const imgUrl = process.env.VUE_APP_BASE_API // img preview url
  ? `${process.env.VUE_APP_BASE_API}${front_fangke}`
  : `${front_fangke}`
export const uploadUrl = process.env.VUE_APP_BASE_API // img upload url
  ? `${process.env.VUE_APP_BASE_API}${front_fangke}/attachment/upload`
  : `${front_fangke}/attachment/upload`
