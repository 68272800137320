import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/css/theme.css'
import buffer from 'buffer'
import pinia from './store/index'
import { loginStore } from './store/login'
import { profix, chainId } from '@/utils/set.js'
// import { ElMessage } from 'element-plus'
import { netData } from '@/utils/data'

window.Buffer = buffer.Buffer

const account = localStorage.getItem('accounts')
const currentNet = localStorage.getItem('currentNet')
if (!currentNet) {
  localStorage.setItem('currentNet', JSON.stringify(netData[0]))
}
console.log(currentNet, '----main----')

if (window.ethereum && window.ethereum.on) {
  window.ethereum.on('accountsChanged', function (res) {
    if (res && res.length > 0) {
      const login = loginStore()
      localStorage.clear()
      window.location.href = `/${profix}`
      login.updateLoginStatus(false)
      login.updateAdminStatus(false)
    }
  })

  window.ethereum.on('chainChanged', function (res) {
    if (res != chainId && account) {
      const login = loginStore()
      setTimeout(() => {
        localStorage.clear()
        window.location.href = `/${profix}`
        login.updateLoginStatus(false)
        login.updateAdminStatus(false)
      }, 2000)
    }
  })
}

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}

createApp(App).use(router).use(ElementPlus).use(pinia).mount('#app')
