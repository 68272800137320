import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { profix } from '@/utils/set.js'
import { ElMessage } from 'element-plus'
import { landRelevant } from '@/api/api.js'

const router = createRouter({
  history: createWebHistory(`/${profix}`),
  routes,
})

router.beforeEach((to, from, next) => {
  const userinfo = localStorage.getItem('userInfo')
  let isLogin = false
  if (userinfo && userinfo != 'null' && userinfo != 'undefined') {
    isLogin = true
  }
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLogin) {
    ElMessage.error('Please log in!')
  } else {
    next()
  }
  // landRelevant
  //   .getCurrentIpApi()
  //   .then((res) => {
  //     if (res && res.statusCode == 200) {
  //       const userinfo = localStorage.getItem('userInfo')
  //       let isLogin = false
  //       if (userinfo && userinfo != 'null' && userinfo != 'undefined') {
  //         isLogin = true
  //       }
  //       if (to.matched.some((record) => record.meta.requiresAuth) && !isLogin) {
  //         ElMessage.error('Please log in!')
  //       } else {
  //         next()
  //       }
  //     } else {
  //       window.location.href = './static/error.html'
  //     }
  //   })
  //   .catch((err) => {
  //     next()
  //   })
})

export default router
